<template>
  <NuxtLink :to="`/${department.attributes.slug}`" class="flex w-full justify-between py-2">
    <div class="leading-snug">
      <span
        v-if="isPromoted"
        class="mr-2 inline-block rounded-lg bg-yellow p-3 py-0.5 text-sm font-medium text-gray-900"
        >Neu</span
      >
      <h3 class="inline-block">{{ department.attributes.title }}</h3>
      <p v-if="isPreventiveCare" class="text-sm font-medium text-green-700">Freie Termine verfügbar</p>
      <BookingSearchSlot v-else :next-free-slot="nextFreeSlot" :pending="pending" :failed="failed" />
    </div>
    <ChevronIcon class="mt-2 text-2xl text-gray-500" />
  </NuxtLink>
</template>

<script setup lang="ts">
import { isPromotedDepartment } from "@/util/isPromotedDepartment";
import ChevronIcon from "~/assets/svg/icons/chevronRight.svg";
import type { Department } from "~/types/strapi/api/department/content-types/department/department";
import { getFirstAvailableOrdinationIdFromServices } from "~/util/getFirstAvailableOrdinationIdFromServices";
import { getServicesAndGroupsFromDepartmentServices } from "~/util/getServicesAndGroupsFromDepartmentServices";
import { isPreventiveCheckupServiceByName } from "~/util/preventiveCare/isPreventiveCheckupService";

const props = defineProps<{
  department: Department;
}>();

const departmentServices = props.department.attributes.services.data;
const servicesAndGroups = getServicesAndGroupsFromDepartmentServices(departmentServices);
const ordinationIds = getFirstAvailableOrdinationIdFromServices(servicesAndGroups);
const { failed, nextFreeSlot, pending } =
  ordinationIds.length > 1 ? useSchedulerGroupSlots(ordinationIds) : useSchedulerServiceSlots(ordinationIds[0]);

const isPromoted = isPromotedDepartment(props.department.attributes.title);
const isPreventiveCare = isPreventiveCheckupServiceByName(props.department.attributes.title);
</script>

<style scoped></style>
