import type { GroupOrService } from "~/types/GroupOrService";
import { isPrimaryService } from "~/util/isPrimaryService";

export const getFirstAvailableOrdinationIdFromServices = (groupOrService: GroupOrService[]): number[] => {
  const ordination = groupOrService.find((service) => isPrimaryService(service.name));
  const firstService = ordination ?? groupOrService[0];

  if (firstService.serviceId) {
    return [firstService.serviceId];
  } else if (firstService.services) {
    return firstService.services;
  }

  return [];
};
