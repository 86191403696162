<template>
  <div>
    <NuxtLink class="flex w-full justify-between py-1" :to="doctorPageLink">
      <NuxtImg
        class="mt-1 h-8 w-8 rounded-full object-cover"
        :alt="
          healthProfessional.avatar.alternativeText ||
          `Bild von ${healthProfessional.firstName} ${healthProfessional.lastName}`
        "
        aria-hidden="true"
        :src="healthProfessional.avatar.formats.thumbnail.url" />
      <div class="ml-2 flex w-full flex-col">
        <h3 class="my-auto text-gray-800" v-html="healthProfessionalFullName" />
        <p class="text-sm text-gray-900">{{ healthProfessionalDepartments }}</p>
      </div>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import type { HealthProfessionalHit } from "~/types/api/Search";
import { concatenateHealthProfessionalDepartments } from "~/util/concatenateHealthProfessionalDepartments";
import { getHealthProfessionalFullName } from "~/util/getHealthProfessionalFullName";

const props = defineProps<{
  healthProfessional: HealthProfessionalHit;
}>();

const doctorPageLink = computed(() => `/team/${props.healthProfessional.slug}`);

const healthProfessionalFullName = computed(() => getHealthProfessionalFullName(props.healthProfessional._formatted));

const healthProfessionalDepartments = ref<string>("");
const departments = computed(() => props.healthProfessional.departments);

healthProfessionalDepartments.value = departments.value && concatenateHealthProfessionalDepartments(departments.value);
</script>

<style scoped></style>
