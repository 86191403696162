import type {
  DepartmentHit,
  HealthProfessionalHit,
  SearchRequest,
  SearchResponse,
  SearchResponseResult,
  ServiceHit,
} from "~/types/api/Search";
import { HIGHLIGHT_POST_TAG, HIGHLIGHT_PRE_TAG } from "~/types/api/Search";
import type { SearchResult } from "~/types/SearchResult";
import { getHitsFromSearchResponseByQueryType } from "~/util/getHitsFromSearchResponseByQueryType";

function prepareSearchBody(searchTerm: string) {
  const body: SearchRequest = {
    queries: [
      {
        attributesToHighlight: ["title"],
        highlightPostTag: HIGHLIGHT_POST_TAG,
        highlightPreTag: HIGHLIGHT_PRE_TAG,
        indexUid: "department",
        q: searchTerm,
      },
      {
        attributesToHighlight: ["firstName", "lastName"],
        highlightPostTag: HIGHLIGHT_POST_TAG,
        highlightPreTag: HIGHLIGHT_PRE_TAG,
        indexUid: "health-professional",
        q: searchTerm,
      },
      {
        attributesToHighlight: ["name"],
        highlightPostTag: HIGHLIGHT_POST_TAG,
        highlightPreTag: HIGHLIGHT_PRE_TAG,
        indexUid: "service",
        q: searchTerm,
      },
    ],
  };

  return body;
}

function transformResponse(apiResults: SearchResponseResult): SearchResult {
  const healthProfessionalResult = getHitsFromSearchResponseByQueryType<HealthProfessionalHit>(
    "health-professional",
    apiResults,
  );
  const departmentResult = getHitsFromSearchResponseByQueryType<DepartmentHit>("department", apiResults);
  const serviceResult = getHitsFromSearchResponseByQueryType<ServiceHit>("service", apiResults);

  return {
    department: [...departmentResult],
    healthProfessional: [...healthProfessionalResult],
    service: [...serviceResult],
  };
}

export async function useSearchApiFetch(searchTerm: string): Promise<SearchResult | undefined> {
  const config = useRuntimeConfig();
  const url = config.public.searchUrl;

  const response = await $fetch<SearchResponse>("multi-search", {
    baseURL: url,
    body: prepareSearchBody(searchTerm),
    method: "POST",
    onRequest({ options }): Promise<void> | void {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${config.public.searchApiToken}`,
      };
    },
  });

  if (response === null) {
    return;
  }

  return transformResponse(response.results);
}
